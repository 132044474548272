
const digest = 'c90015db7d4ef330f349303abe6e73321184fe9988f1a056fee7d9659aeb3ee8';
const css = `._card_rrs2e_1 {
  background: #fff;
  border: 1px solid #e7e7ed;
}

._withPadding_rrs2e_6 {
  padding: 1rem;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"card":"_card_rrs2e_1","withPadding":"_withPadding_rrs2e_6"};
export { css, digest };
  