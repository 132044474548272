
const digest = '68e8b755d766dd2b2f0c3c326163dc57376ef97d8fd488d2f09ff988c2af9187';
const css = `._fieldGroupLabel_1y59t_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-neutral-10);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"fieldGroupLabel":"_fieldGroupLabel_1y59t_1"};
export { css, digest };
  