
const digest = '5003a54870034c73dea77df103bab08be65112ccd47b2d564026f635af481f45';
const css = `._avatar_1f1ka_1 {
  background-color: var(--color-legacy-gray-2);
  border-radius: 50%;
  display: block;
  margin-right: 0.75rem;
}

._medium_1f1ka_8 {
  height: 2rem;
  width: 2rem;
}

._small_1f1ka_13 {
  height: 1.5rem;
  width: 1.5rem;
}

._xSmall_1f1ka_18 {
  height: 1rem;
  width: 1rem;
}

._avatarSelectOption_1f1ka_23 {
  display: flex;
}

._avatarSelect_1f1ka_23 {
  align-items: center;
  display: flex;
}

._avatarInitials_1f1ka_32 {
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: 600;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"avatar":"_avatar_1f1ka_1","medium":"_medium_1f1ka_8","small":"_small_1f1ka_13","xSmall":"_xSmall_1f1ka_18","avatarSelectOption":"_avatarSelectOption_1f1ka_23","avatarSelect":"_avatarSelect_1f1ka_23","avatarInitials":"_avatarInitials_1f1ka_32"};
export { css, digest };
  