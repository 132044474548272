
const digest = '732820a728db981e6526df9efadddb6cbdf99f3dbd2da80698a57ac770d938e6';
const css = `._wrapper_1od4f_1 {
  z-index: 5;

  background: var(--color-white);
  width: 100%;
}

._filters_1od4f_8 {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 12rem repeat(4, 1fr) 1fr;
  height: 100%;
  padding: 1rem;
  width: 100%;
  z-index: 15;
}

._financials_1od4f_18 {
  border: 1px solid var(--color--neutral-10);
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  height: 100%;
  color: var(--color-neutral-10);
  padding: 0.5rem 1.5rem 0.5rem 1rem;
  cursor: pointer;
  margin-right: 1rem;
}

._financials_1od4f_18:hover {
  color: var(--color-neutral-40);
  border: 1px solid var(--color-neutral-40);
}

._filtersRight_1od4f_35 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

._filters_1od4f_8 ._select_1od4f_41 {
  z-index: 5;
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"wrapper":"_wrapper_1od4f_1","filters":"_filters_1od4f_8","financials":"_financials_1od4f_18","filtersRight":"_filtersRight_1od4f_35","select":"_select_1od4f_41"};
export { css, digest };
  